exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bilder-tsx": () => import("./../../../src/pages/bilder.tsx" /* webpackChunkName: "component---src-pages-bilder-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-pages-salgsbetingelser-tsx": () => import("./../../../src/pages/salgsbetingelser.tsx" /* webpackChunkName: "component---src-pages-salgsbetingelser-tsx" */),
  "component---src-pages-takk-tsx": () => import("./../../../src/pages/takk.tsx" /* webpackChunkName: "component---src-pages-takk-tsx" */)
}

